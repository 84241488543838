import React, { useState } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container } from 'react-bootstrap';

import Layout from "../components/layout";
import SEO from "../components/seo";
import SurveyForm from '../components/survey';
import ConsentModal from '../components/survey/consentModal';
import styles from '../components/styles/upload.module.scss';
import CCSLogo from '../images/ccs-logo.png';

const CitizenUploadPage = () => {
    const { t } = useTranslation('upload');
    const [success, setSuccess] = useState(false);

    return (
        <Layout>
            <SEO title={t('title')} />

            <main className={styles.main}>
                <Container className={styles.container}>
                    {success ? (
                        <div>
                            <p className="text-center my-5">
                                <span className="h1">{t('success_1')}</span>
                                <br /><br />
                                <span className="h2">{t('success_2')}</span>
                            </p>
                        </div>
                    ) : (
                        <>
                            <img src={CCSLogo} alt="COVID-19 Citizen Science" className="mb-4" />

                            <p>
                                Dear COVID-19 Citizen Scientist,
                            </p>

                            <p>
                                Thank you for agreeing to join us in partnering with Covidseeker, a hotspot mapping tool that will help 
                                researchers learn more about the transmission patterns of COVID-19.
                            </p>

                            <p>
                                If you opt in, you can also be notified by Covidseeker if you may have been exposed to a person who has tested 
                                positive for COVID-19.
                            </p>

                            <p>
                                We appreciate your commitment to fighting COVID-19.  If you have questions, contact us 
                                at <a href="mailto:covid19@eurekaplatform.org">covid19@eurekaplatform.org</a>. Questions about 
                                Covidseeker? Please contact <a href="mailto:Yulin.Hswen@ucsf.edu">Yulin.Hswen@ucsf.edu</a>.
                            </p>

                            <hr className="my-4" />

                            <SurveyForm formType="citizen" onSuccess={() => setSuccess(true)} />
                        </>
                    )}
                </Container>
            </main>

            <ConsentModal />
        </Layout>
    );
};

export default CitizenUploadPage;
